import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
    const [menustate, setMenustate] = useState(false);
    return (
        <div className="menucontainer01">
            <div className="menucontainer02">
                <div className="menulogo">
                    <NavLink>
                        <img src="./image/shalom.png" alt="" />
                    </NavLink>
                </div>
                <ul className="ulliste">
                    <li className="menuitem"><NavLink to='/'>Accueil</NavLink></li>
                    <li className="internmenu">
                        <span className="dropbtn">Nos formules</span>
                        <ul className="dropdown-content">
                            <li><NavLink to='/internat'>Internat</NavLink></li>
                            <li><NavLink to='/externat'>Externat</NavLink></li>
                        </ul>
                    </li>
                    <li className="menuitem"><NavLink to='/aboutus'>A propos de nous</NavLink> </li>
                    <li className="menuitem"><NavLink to='/Contact'>Contact</NavLink></li>
                </ul>
            </div>
            <div className="menucontainer02bis">
                <div className="menulogo">
                    <NavLink>
                        <img src="./image/shalom.png" alt="" />
                    </NavLink>
                </div>
                <div>
                    <div className="menutoggle" onClick={() => setMenustate(!menustate)}>
                        <div className="barr01" style={{rotate: menustate ? '45deg' : '0deg', top: '6px'}}></div>
                        <div className="barr02" style={{display: menustate ? 'none' : 'block'}}></div>
                        <div className="barr03" style={{rotate: menustate ? '135deg' : '0deg', top: '-6px'}}></div>
                    </div>
                    <ul className="ulliste" style={{display: menustate ? 'block' : 'none'}}>
                        <li className="menuitem"><NavLink to='/'>Accueil</NavLink></li>
                        <li className="internmenu">
                            <span className="dropbtn">Nos formules</span>
                            <ul className="dropdown-content">
                                <li><NavLink to='/internat'>Internat</NavLink></li>
                                <li><NavLink to='/externat'>Externat</NavLink></li>
                            </ul>
                        </li>
                        <li className="menuitem"><NavLink to='/aboutus'>A propos de nous</NavLink> </li>
                        <li className="menuitem"><NavLink to='/Contact'>Contact</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navigation;