import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';


const Carousel = () => {
    const dispatch = useDispatch();
    const [imagebdd, setImagebdd] = useState([
        {
            imgsrc: './image/131369580_394665591770097_805356511816684384_n.jpg',
            id: 0,
            position: 0,
        },
        {
            imgsrc: './image/roll.jpg',
            id: 1,
            position: 1,
        },
        {
            imgsrc: './image/approche01.jpg',
            id: 2,
            position: 2,
        },
        {
            imgsrc: './image/131262156_203383754750747_7319901545224518318_n(1).jpg',
            id: 3,
            position: 3,
        },
        {
            imgsrc: './image/131369580_394665591770097_805356511816684384_n.jpg',
            id: 4,
            position: 4,
        },
        {
            imgsrc: './image/20220829_110208 (2).jpg',
            id: 5,
            position: 5,
        },
        {
            imgsrc: './image/atelier.jpg',
            id: 6,
            position: 6,
        },
        {
            imgsrc: './image/exercices.jpg',
            id: 7,
            position: 7,
        },
        {
            imgsrc: './image/citeAnnif.jpg',
            id: 8,
            position: 8,
        },
        {
            imgsrc: './image/Salledebeauté.jpg',
            id: 9,
            position: 9,
        },
        {
            imgsrc: './image/starex.jpg',
            id: 10,
            position: 10,
        },
        {
            imgsrc: './image/nutri form.jpg',
            id: 11,
            position: 11,
        },
        {
            imgsrc: './image/monalimentation.jpeg',
            id: 12,
            position: 12,
        },
        {
            imgsrc: './image/toamasina.jpg',
            id: 13,
            position: 13,
        },
        {
            imgsrc: './image/jeux.jpg',
            id: 14,
            position: 14,
        },
        {
            imgsrc: './image/Mamie.M1.jpg',
            id: 15,
            position: 15,
        },
        {
            imgsrc: './image/crudité nutri.jpg',
            id: 16,
            position: 16,
        },
    ]);

    const [imgindex, setImgindex] = useState(1);
    const indexup = () => {    
        setImgindex((imagebdd.length + imgindex + 1) % imagebdd.length);
        console.log(imgindex);
        
    }
    const indexdown = () => {
        setImgindex((imagebdd.length + imgindex - 1) % imagebdd.length);
        console.log(imgindex);
    }

    useEffect(() => {
        showdiapos();
        const imginterval = setInterval(() => {
            pushup();
        }, 5000);
        return () => {
            clearInterval(imginterval);
        }

    }, [imagebdd]);

    const [newlist, setNewlist] = useState([]);
    const showdiapos = () => {
        const templist = imagebdd && imagebdd
            .filter((image) => (Number(image.position) > 3 && Number(image.position) < 7 ))
            .sort((a, b) => (a.position - b.position))
            .map((image, index) => (
            <div className={"diapogrid0"+ index} key={image.id}>
                <img key={image.id} src={image.imgsrc} alt={image.imgsrc} />
            </div>
        ));
        setNewlist(templist);
        
    }

    const pushup = () => {
        const newBdd = imagebdd;
        for (let i = 0; i < imagebdd.length; i++){
            newBdd[i].position = (newBdd[i].position + 1) % imagebdd.length;
        }
        setImagebdd(newBdd);
        showdiapos();
    }
    const pushdown = () => {
        const newBdd = imagebdd;
        for (let i = 0; i < imagebdd.length; i++){
            newBdd[i].position = (imagebdd.length + newBdd[i].position - 1) % imagebdd.length;
        }
        setImagebdd(newBdd);
        showdiapos();
    }


    return (
        <div className="banner02">
        <h2 style={{marginTop: '40px'}}>Vieillir en beauté et en bonne santé ! </h2>
        <div className="separator02" style={{display: 'block', margin: 'auto auto 20px', textAlign: 'center'}}></div>
            <div className="carouselban">
                <div className="imagegallery">
                    <div>
                        <div className="gallerycontainer">
                            <div className="leftchevron">
                                <button onClick={() => pushdown()}><i className="fa fa-chevron-left"></i></button>
                            </div>
                            <div className="imagecontainer">
                                <div className="slidecontainer">{newlist}</div>
                            </div>
                            <div className="rightchevron">
                                <button onClick={() => pushup()}><i className="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mytext02">
                    <div>
                        <p className="">
                            La Maison d'accueil Shalom s'engage à améliorer la qualité de vie des personnes âgées en leur offrant une expérience personnalisée et stimulante. Dirigée par Dr Josiane Rakotomalala, Shalom - Maison d'accueil accueille toutes les personnes âgées en bonne santé et celles atteintes de maladies dégénératives liées à l'âge telles qu'Alzheimer ou Parkinson. <br />
                            
                            Son équipe multidisciplinaire travaille de plein pieds afin d'apporter des soins de qualité et une attention particulière à chaque résident, y compris une alimentation adaptée, des activités physiques douces et des ateliers de thérapie non médicamenteuse.
                            
                        </p>
                    </div>
                </div>
            </div>



        </div>
    )
}
export default Carousel;