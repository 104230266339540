import React from "react";
import Navigation from "../Template-Parts/Navigation";
import Intro from "../Components/Intro";
import Cibles from "../Components/Cibles";
import Team from "../Components/Team";
import Footer from "../Template-Parts/Footer";
import Banneerhome06 from "../Components/Banneerhome06";
import Carousel from "../Components/Carousel";

const Aboutus = () => {
    return (
        <div className="stdcontainer">
            <Navigation />
            <section>
                <div className="nosban01">
                    <h1>A propos de nous</h1>
                </div>
            </section>
            <Carousel />
            <Team />
            <Banneerhome06 />
            <Footer />
        </div>
    );
}

export default Aboutus;