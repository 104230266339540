import React from "react";

const Banneerhome02 = () => {
    return (
        <div className="container01">
            {/* <div className="firstban">
                <h2>Comment rester en bonne santé avec l'âge ?</h2>
                <div className="separator01"></div>
                <p className="mytext01">
                Avec le temps, le stress, une alimentation déséquilibrée et des habitudes de vie peu saines peuvent favoriser l’apparition de maladies chroniques telles que le diabète, l’hypertension artérielle, les rhumatismes ou l’arthrose. Les organes vitaux comme le foie, les reins ou le pancréas peuvent également être affectés. À cela s’ajoutent les risques de maladies dégénératives liées à l’âge, comme Alzheimer ou Parkinson, souvent aggravées par un diagnostic tardif.  <br/><br/>

                Cependant, adopter une alimentation saine et des gestes simples au quotidien peut considérablement améliorer votre qualité de vie et prévenir de nombreuses pathologies. Ces changements sont à la portée de tous et peuvent transformer votre avenir. Prenez soin de votre santé dès aujourd'hui — chaque petit geste compte !

                </p>
            </div> */}

            <div className="testprime">
                <div className="leftone">    
                    <h2 style={{textAlign: 'center'}}>Comment rester en bonne santé avec l'âge ?</h2>
                    <div className="separator01"></div>
                    <p className="mytext01">
                    Avec le temps, le stress, une alimentation déséquilibrée et des habitudes de vie peu saines peuvent favoriser l’apparition de maladies chroniques telles que le diabète, l’hypertension artérielle, les rhumatismes ou l’arthrose. Les organes vitaux comme le foie, les reins ou le pancréas peuvent également être affectés. À cela s’ajoutent les risques de maladies dégénératives liées à l’âge, comme Alzheimer ou Parkinson, souvent aggravées par un diagnostic tardif.  <br/><br/>

                    Cependant, adopter une alimentation saine et des gestes simples au quotidien peut considérablement améliorer votre qualité de vie et prévenir de nombreuses pathologies. Ces changements sont à la portée de tous et peuvent transformer votre avenir. Prenez soin de votre santé dès aujourd'hui — chaque petit geste compte !
                    </p>
                </div>
                <div className="rightone">
                    <img src="./image/boule02.jpg" alt="" />
                </div>

            </div>

        </div>
    )
}

export default Banneerhome02;