import React, {useState, useRef} from "react";
import { useDispatch } from "react-redux";
import { sendstandardmail } from "../action/standardmail.action";

const Banneerhome06 = () => {
    const dispatch = useDispatch();
    const [subject, setSubject] = useState('');
    

    const formref = useRef();
    const sendmail = (e) => {
        e.preventDefault();
        const data = {
            'nom': formref.current[0].value,
            'mail': formref.current[1].value,
            'telephone': formref.current[2].value,
            'sujet': subject,
            'message': formref.current[4].value,
        }
        dispatch(sendstandardmail(data));
        formref.current.reset();
    }
    



    return (
        <div className="stdcontainer">
            <div className="Bannerhome06">
                <div className="formcontainer">
                <h2>Une question ?</h2>
                <div className="separator01"></div>
                    <form ref={formref} onSubmit={(e) => sendmail(e)}>
                        <input type="text" placeholder="Votre nom" name="nom" />
                        <input type="text" placeholder="Votre e-mail" name="email" />
                        <input type="text" placeholder="Votre téléphone"  name="telephone" />
                        <select name="sujet" id="" onChange={(e) => setSubject(e.target.value)}>
                            <option value="0">Sélectionner un sujet</option>
                            <option value="Formations">Formations</option>
                            <option value="Internat">Internat</option>
                            <option value="Externat">Externat</option>
                            <option value="Activités">Activités</option>
                            <option value="Autre">Autre</option>
                        </select>
                        <textarea placeholder="Votre message ..." rows={10} name="message"></textarea>
                        <input type="submit" value="Envoyer" />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Banneerhome06;