import { NTTRP_FORM, SBE_FORM, SEND_CONTACT, STANDARD_MAIL } from "../action/standardmail.action";

const initialState = {reponse : ''};

export const standardmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case STANDARD_MAIL:
            alert(action.payload.message);
            return action.payload;
        case SEND_CONTACT:
            alert(action.payload.message);
            return action.payload;
        case SBE_FORM:
            alert(action.payload.message);
            return action.payload;
        case NTTRP_FORM:
            alert(action.payload.message);
            return action.payload;
        default:
            return state;
    }
}