import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";

export const STANDARD_MAIL = 'STANDARD_MAIL';
export const SEND_CONTACT = 'SEND_CONTACT';
export const SBE_FORM = 'SBE_FORM';
export const NTTRP_FORM = 'NTTRP_FORM';

export const sendstandardmail = (data) => {
    return (dispatch) => {
        return axios.post('https://www.shalom.mg/info.php', data, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
            dispatch({ type: STANDARD_MAIL, payload: res.data });
        }
        );
    }
}

export const sendacontact = (data) => {
    return (dispatch) => {
        return axios.post('https://www.shalom.mg/contact.php', data, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
            dispatch({ type: SEND_CONTACT, payload: res.data });
        }
        );
    }
}

export const sbeform = (data) => {
    return (dispatch) => {
        return axios.post('https://www.shalom.mg/sbe.php', data, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
            dispatch({ type: SBE_FORM, payload: res.data });
        });
    }
}

export const nttrpform = (data) => {
    return (dispatch) => {
        return axios.post('https://www.shalom.mg/nttrp.php', data, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
            dispatch({ type: NTTRP_FORM, payload: res.data });
        });
    }
}