const Team = () => {
    return (
        <section>
            <div className="spacer01"></div>
            <div className="bann-content08">
                <h2 style={{ textAlign: "center" }}>Notre équipe</h2>

                <div className="separator01"></div>

                <div className="bann-content03 " style={{ border: "solid 4px $color05" }}>
                    <div className="carde-intern">
                    </div>

                    <div className="bann-content05">
                        <div className="equipe02 mypara">
                            <h3>Un médecin coordonnateur et gérante du centre</h3>
                            <p style={{ width: "90%" }}>
                                Le Dr Josiane Rakotomalala assure l'élaboration et la planification des projets du centre, garantit le bon déroulement des activités, et en assure leur diversification afin d'éviter la routine.
                            </p>
                            <p>
                                <span style={{ textDecoration: "underline" }}>Manager et Leader</span> : Elle coordonne, gère, organise et supervise toutes les activités du centre. Elle effectue le test de tous les entrants, en les classant selon leur état de santé. Elle supervise les différents intervenants, et effectue le bilan mensuel des activités du centre.
                            </p>
                            <p>
                                <span style={{ textDecoration: "underline" }}>Coach en Nutrithérapie</span> <i>(notre aliment = notre médicament)</i> : Elle établit une alimentation équilibrée, adaptée et individualisée au quotidien pour les résidents du centre. Elle organise des séances de formation sur la "pharmacie alimentaire"; un sujet qui traite des interactions alimentaires, des couleurs de la santé, et de l'élaboration de l'<b>assiette antioxydant</b>, ou <b>assiette anti-cancer</b>.
                            </p>
                            <p style={{ marginBottom: 0 }}>
                                <span style={{ textDecoration: "underline" }}>Coach en Santé Bien-être</span> : Elle propose aux résidents du centre des activités sur le ralentissement du vieillissement comme la rigologie; et traite d'autres sujets comme l'élimination des métaux lourds dans le corps, la réduction du stress, la prévention du dysfonctionnement hormonal, et "comment bien dormir".
                            </p>
                        </div>
                        <div className="img-go">
                            <img
                                src="../image/dr02.fw.png"
                                alt="docteur nutrithérapeute"
                                className="standard-image mydoc goout"
                                style={{ padding: "20px 20px 0 20px" }}
                            />
                        </div>
                    </div>


                    
                    <div className="grid-me" style={{width: '100%'}}>
                        <div className="vide"></div>
                        <div className="sec01">
                            <img src="./image/Jacky02.jpg" style={{ zIndex: 1 }} className="standard-image" alt="personnes agées dans piscine" />
                        </div>
                        <div className="sec02">
                            <div>   
                                <div className="pousseoir">
                                    <h3>Un Directeur artistique</h3>
                                    <p>
                                        Le rôle du directeur artistique chez Shalom - Maison d'Accueil est de créer un environnement stimulant pour les résidents en favorisant des activités créatives telles que la peinture, le chant et la musique. Il vise à stimuler les cinq sens et à encourager les résidents à exprimer leur créativité tout en réduisant les troubles du comportement et l'isolement.
                                    </p> <br/>
                                    <h3>Des animatrices</h3>
                                    <p>
                                        Les animatrices chez Shalom - Maison d'Accueil sont des professionnelles engagées et conviviales, qui contribuent à offrir un environnement stimulant et encourageant pour les personnes âgées. Grâce à leur savoir-faire, leur écoute et leur disponibilité, elles permettent à nos résidents de vivre des moments de partage et d'échange, tout en contribuant à leur bien-être physique et mental.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div class="separator01 sep"></div> */}
                    </div>

                    <div className="bannerhome03">
                        <div className="leftpart fondimage03"></div>
                        <div className="rightpart" style={{ boxShadow: "none" }}>
                            <h3 style={{ textAlign: "center", textDecoration: 'none', fontWeight: 'bold' }}>Un coach en Zumba senior</h3>
                            <div className="separator01"></div>
                            <ul>
                                <li>Il travaille en étroite collaboration avec le coach de gymnastique douce</li>
                                <li>Il apprend des mouvements adaptés et coordonnés aux seniors</li>
                                <li>Il associe cette activité à des chants et danses</li>
                            </ul>
                        </div>

                    </div>

                    <div className="bannerhome04">       
                        <div className="leftpart rightpart02" style={{ boxShadow: "none" }}>
                            <h3 style={{ textAlign: "center", textDecoration: 'none', fontWeight: 'bold' }}>Un coach en Gym douce</h3>
                            <div className="separator01"></div>
                            <ul>
                                <li>Il prépare et organise les exercices musculaires</li>
                                <li>Il apprend aux résidents les exercices de redressement et d'équilibre</li>
                                <li>Il aide les seniors aux divers exercices d'étirements</li>
                            </ul>
                        </div>
                        <div className="rightpart fondimage04"></div>
                    </div>

                    <div className="bannerhome03">
                        <div className="leftpart fondimage05"></div>
                        <div className="rightpart rightpart02" style={{ boxShadow: "none" }}>
                            <h3 style={{ textAlign: "center", textDecoration: 'none', fontWeight: 'bold' }}>Un personnel administratif</h3>
                            <div className="separator01"></div>
                            <ul>
                                <li>Il assure toutes les tâches administratives</li>
                                <li>Il crée et classe tous les dossiers des patients</li>
                                <li>Il crée un journal familial pour les personnes loin de leur proche</li>
                                <li>Il maintient un lien social avec l’extérieur</li>
                                <li>Il planifie le voyage annuel en mer après validation du gérant du centre</li>
                            </ul>
                        </div>

                    </div>

                    <div className="bannerhome04">       
                        <div className="leftpart" style={{ boxShadow: "none" }}>
                            <h3 style={{ textAlign: "center", textDecoration: 'none', fontWeight: 'bold' }}>Des assistantes</h3>
                            <div className="separator01" style={{background: '#fff'}}></div>
                            <ul>
                                <li>Elles assurent les moments de lecture</li>
                                <li>Elles organisent l’atelier de cuisine</li>
                                <li>Elles assurent les jeux de société</li>
                                <li>Elles assistent et aident les autres membres de l'équipe</li>
                            </ul>
                        </div>
                        <div className="rightpart fondimage06"></div>
                    </div>


                </div>

                
                <div className="vide"></div>
                <h3 style={{ textAlign: "center", textDecoration: 'none', fontWeight: 'bold' }}>Notre approche</h3>
                <div className="separator01"></div>
                <div className="grid-me">
                    <div className="sec01">
                        <img src="./image/approche01.jpg" style={{ zIndex: -1 }} className="standard-image" alt="personnes agées dans piscine" />
                    </div>
                        <div className="sec02">
                            <div>   
                            <div className="pousseoir">
                                <h3>S'occuper de leur santé</h3>
                                <p>
                                Notre approche chez Shalom - Maison d'Accueil est unique et innovante. Nous accordons une attention particulière à l'alimentation, en proposant des options adaptées à leurs besoins nutritionnels grâce à la Nutrithérapie. Nous proposons également des activités physiques telles que la gymnastique douce pour favoriser le renforcement musculaire et le maintien de l'équilibre.  

                                <br/><br/>

                                Notre approche combine les soins médicaux et la thérapie non médicamenteuse pour créer un environnement de soins holistiques, où les personnes âgées sont traités avec respect et dignité. La bonne alimentation, l'activité physique, la stimulation cognitive et l'interaction sociale sont des éléments clés pour une vie saine et heureuse chez les personnes âgées. Shalom - Maison d'Accueil est la solution idéale pour ceux qui cherchent un environnement sûr et agréable pour leurs proches âgés. 

                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <div class="separator01 sep"></div> */}
                        
                    <div className="sec03">
                        <div>
                            <div className="pousseoir02">
                                <h3>Favoriser leur bien-être</h3>
                                <p>
                                Notre équipe chez Shalom - Maison d'Accueil est fière d'offrir un environnement agréable pour nos résidents. Nous sommes conscients que chaque personne est unique, avec ses propres besoins et préférences, et nous nous efforçons de créer un environnement où chacun se sent chez soi. Nos résidents sont encouragés à participer à diverses activités de leur choix comme le jardinage, l'art, etc. 
                                <br/><br/>

                                Nous ambitionnons à assurer le développement psychosocial et la stimulation cognitive de nos résidents. Pour ce faire, nous utilisons différentes techniques de stimulation cognitive, telles que des tests et des jeux de mémoire, ainsi que des moments d'échanges sur l'histoire de nos résidents grâce à la Réminiscence. Nous sommes également attachés à assurer une prise en charge sociale et spirituelle complète pour chaque résident afin de procurer un confort physique et psychologique optimal. 

                                </p>
                            </div>
                        </div>        
                    </div>
                    <div className="sec04">
                        <img src="./image/approche02.jpg" style={{ zIndex: -1 }} className="standard-image" alt="personnes agées avec ballon" />
                    </div>
                </div>

                
            </div>
        </section>
    );
}

export default Team;
