import React from "react";
import Navigation from "../Template-Parts/Navigation";
import Footer from "../Template-Parts/Footer";
import Banneerhome06 from "../Components/Banneerhome06";
import Bannierre from "../Components/Bannierre";


const Externat = () => {
    return (
        <>
            <Navigation />
            <section>
                <div className="nosban01">
                    <h1>Externat</h1>
                </div>
            </section>
            <section>
                <div className="bann-content">
                    <h2>Vos journées de détente chez Shalom</h2>
                    <div className="separator01"></div>
                    <p className="mytext01" style={{width: '80%', margin: 'auto'}}>
                    Notre programme spécial senior et les soins que nous disposons aux internats sont désormais accessibles en mode externat. Il est en effet possible de participer aux activités quotidiennes que nous proposons, sur inscription ou à la demande. Votre parent pourra revenir dormir chez lui, le soir, tout en particpant aux activités et animations que nous proposons, le jour, selon sa disponibilité. Il est également possible de réserver un service spécial de transport pour les déplacements. 
                    </p>
                </div>
            </section>
                    <div className="spacer01"></div>
            <section>
                <div className="bann-content">
                    <div>
                        <h2>Une formule modulable</h2>
                        <div className="separator01"></div>
                        <div className="grid-caracteristiques">
                            <div className="caracteristiques">Caractéristiques</div>
                            <div className="details">Détails</div>

                            <div className="grid-conditions">Admission</div>
                            <div className="conditions-details">Le contrôle médical n'est pas obligatoire, mais reste recomandé afin de nous permettre un suivi médical et un meilleur accompagnement (conseils et soins). </div>

                            <div className="grid-duree">Durée de séjour</div>
                            <div className="duree-details">
                            Il est possible de passer une journée, une demi-journée ou une nuitée au centre selon le planning des activités, ou les soins demandés.</div>

                            <div className="grid-restauration">Restauration</div>
                            <div className="restauration-details">Un service de restauration est proposé par notre équipe, avec Dr Josiane Rakotomalala, également coach en nutrithérapie, selon les besoins nutritionnels de chacun. La réservations doit se faire au préalable afin de faciliter les préparations.</div>

                            <div className="grid-activites">Activités</div>
                            <div className="activites-details">Diverses activités sont organisées au quotidien et régulièrement chez Shalom - Maison d'accueil. L'intéressé pourra s'incrire à l'activité de son choix, selon le planning établi ou effectuer une inscription à l'avance pour les demandes spéciales. Des sorties sont régulièrement organisées, dont des vacances au bord de la mer, une fois par an.</div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Bannierre />
            

            <section>
                <div class="bann-content">
                    <div class="doctor-word border-me">
                        <img src="./image/ballons.jpeg" class="prez" alt=""/>  
                        <h2 style={{textAlign: 'left'}}>Les activités</h2>
                        <div class="separator02"></div>
                        <p>
                            Nous proposons des <b>activités physiques ou non</b>, adaptées aux personnes âgées, pour les aider à rester actifs et en bonne santé. La participation à ces activités relève de la volonté et l'état de santé du résident. Un planning hébdomadaire et mensuel de ces activités est établi en amont. Elles sont animées par des professionnels selon leur domaine respectif, avec l'aide de nos assistantes. Voici la liste de ces activités : <br/><br/> 
                        </p>
                        <ul>
                            <li><i class="fa fa-check usercheck"></i> <b>Activites physique </b>: Zumba senior, gymnastique douce spéciale senior (jeu de parachute, anneaux d’activité, ballon de manipulation,ballon gym, balles d’exercices main-doigt, billard Hollandais, mini-bowling, ...)</li>
                            <li><i class="fa fa-check usercheck"></i> <b>Diverses animations et jeux collectifs </b>: jeux de société, jeux de mémoire, lecture (nouvelles et revues de presse)</li>
                            <li><i class="fa fa-check usercheck"></i> <b>Atelier artistique </b>: musique, chant, peinture</li>
                            <li><i class="fa fa-check usercheck"></i> <b>Art culinaire</b></li>
                            <li><i class="fa fa-check usercheck"></i> <b>Divers évènements </b>: sorties, spectacle animé par les seniors, ...</li>
                        </ul>
                        
                    </div>
                </div>
            </section>
            <Banneerhome06 />
            <Footer />
        </>
    );
}

export default Externat;