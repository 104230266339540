const Cibles = () => {
    return (        
        <section>
            <div className="bann-contentxx">
                <div className="bann-content07">
                <h2 style={{textAlign: 'center'}}>Les résidents du Centre </h2>
                <div className="separator01"></div>
                    <div className="mytext03">
                        <p style={{fontWeight: "bold"}}>Nous avons le plaisir d'accueillir et de prendre soin de :</p>
                        <ol>
                            <li>Toutes personnes âgées en bonne santé apparente susceptibles de prendre soins de leur physique en vue de prévenir toutes les maladies dégénératives liées à l’âge : 
                                <ul>
                                    <li>en leur apprenant à prendre des aliments riches en antioxydants</li>
                                    <li>en leur apprenant des différentes postures de prévention et de bien-être</li>
                                    <li>en leur faisant pratiquer des exercices physiques douces pour renforcer leur musculation</li>
                                    <li>en leur apprenant des mouvements de coordinations adaptés pour stimuler leur mémoire et ainsi éviter certaines démences.</li>
                                </ul>
                            </li>
                            <li>Les malades atteints d’Alzheimer :
                                <ul>
                                    <li>pour éviter la progression de la maladie ou même l’améliorer</li>
                                </ul>
                            </li>
                            <li>
                                Les malades atteints de la maladie de Parkinson au stade de début ou pas :
                                <ul>
                                    <li>pour stimuler leur renforcement musculaire</li>
                                    <li>pour diminuer la progression de la maladie ou même la maîtriser</li>
                                </ul>
                            </li>
                            <li>Les personnes âgées souffrant des douleurs articulaires ou de dos chroniques :
                                <ul>
                                    <li>en leur apportant des thérapies non médicamenteuses</li>
                                </ul>
                            </li>
                            <li>Les personnes en convalescence, ou qui viennent sortir d'hôpital :
                                <ul>
                                    <li>afin de se rétablir rapidement grâce à la nutrithérapie.</li>
                                </ul>
                            </li>
                        </ol>
                    </div> 

                </div>
            </div>
        </section>
    )
}

export default Cibles;