import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { nttrpform, sbeform } from "../action/standardmail.action";


const Banneerhome03 = () => {
    const [sbe, setSbe] = useState(false);
    const [ntrp, setNtrp] = useState(false);
    const [mytop, setMytop] = useState('');
    const sberef = useRef();
    const nttrpref = useRef();
    const dispatch = useDispatch();



    const showsbemodal = (e) => {
        setSbe(true);
        setMytop(e.pageY - e.clientY + 'px');
        document.body.style.overflow = 'hidden';
    }
    const sendsbe = (e) => {
        e.preventDefault();

        const data = {
            nomform: sberef.current[0].value,
            groupe: sberef.current[1].value,
            dateform: sberef.current[2].value,
            nom: sberef.current[3].value,
            mail: sberef.current[4].value,
            telephone: sberef.current[5].value,
        };
        dispatch(sbeform(data));
        sberef.current.reset();
    }
    const removemodal01 = (e) => {
        if (e.target.className == 'modal') {
            setSbe(false);
            document.body.style = 'auto';
        }
    }


    const showntrpmodal = (e) => {
        setNtrp(true);
        setMytop(e.pageY - e.clientY + 'px');
        document.body.style.overflow = 'hidden';
    }
    const sendnttrp = (e) => {
        e.preventDefault();

        const data = {
            nomform: nttrpref.current[0].value,
            groupe: nttrpref.current[1].value,
            dateform: nttrpref.current[2].value,
            nom: nttrpref.current[3].value,
            mail: nttrpref.current[4].value,
            telephone: nttrpref.current[5].value,
        };
        dispatch(nttrpform(data));
        nttrpref.current.reset();
    }

    
    const removemodal02 = (e) => {
        if (e.target.className == 'modal') {
            setNtrp(false);
            document.body.style = 'auto';
        }
    }



    return (
        <div className="stdcontainer">
            <h2>Nos mini-formations - santé</h2>
            <div className="separator01"></div>
            <div className="textpresentation">
                <p>Vous avez le pouvoir de changer les choses. Chaque choix et chaque geste que vous faites aujourd'hui peut garantir le bien-être et éloigner encore les risques de maladies graves demain pour vous et votre famille. Le meilleur investissement que vous pouvez faire aujourd'hui c'est sur votre santé et celle de votre famille; passez à l'action aujourd'hui, pour vous et pour ceux que vous aimez.</p><br />
                <p>Nous proposons régulièrement des formations de 2 heures, sur inscription, chacune sur le thème de la santé-bien-être et sur la nutrithérapie. Ces sessions sont conçues pour vous offrir des connaissances pratiques et applicables immédiatement dans votre quotidien.</p>
            </div>

            <div className="bannerhome03">
                <div className="leftpart fondimage01">
                    {/* <img src="./image/mamie.jpg" alt="mamie" /> */}
                </div>
                <div className="rightpart rightpart02">
                    <h3>Santé & Bien-être</h3>
                    <div className="separator01"></div>
                    <p>
                    Le Dr Josiane partage ses conseils pratiques et astuces pour améliorer votre longévité et maintenir un équilibre de vie optimal. Explorez les activités physiques adaptées et leurs bienfaits, telles que la rigologie ou la danse avec des mouvements coordonnés, pour renforcer votre santé de manière durable. Apprenez également à mieux comprendre les hormones du bonheur, leur rôle crucial et les moyens de les activer pour favoriser votre bien-être.
                    </p>
                    <button className="mybutton01" onClick={(e) => showsbemodal(e)}> Ça m'intéresse !</button>
                </div>
            </div>

            <div className="bannerhome04">
                <div className="leftpart">
                    <h3>Nutrithérapie</h3>
                    <div className="separator01"></div>
                    <p>Prenez soin de votre santé et de celle de votre famille grâce à une alimentation consciente et équilibrée. Le Dr Josiane vous guide pour comprendre les bienfaits des aliments, éviter les dangers d'une mauvaise nutrition et intégrer des astuces simples au quotidien. Apprenez à réduire l’exposition aux pesticides, à limiter les métaux lourds et à choisir des aliments qui soutiennent votre bien-être. Transformez chaque repas en un acte de prévention et de santé, selon le principe : "Mon aliment, mon médicament.".</p>
                    <button className="mybutton01" onClick={(e) => showntrpmodal(e)} > Ça m'intéresse !</button>
                 </div>
                <div className="rightpart fondimage02"></div>
            </div>

            <section className="activities">
                <h2>Nos activités</h2>
                <div className="separator01"></div>
                <p className="mypara">Nous proposons un large éventail d’activités stimulantes et enrichissantes, axées sur des thérapies non médicamenteuses pour favoriser le bien-être physique et mental. Ouvertes à toutes et à tous, quels que soient votre âge ou votre situation, nos activités combinent pratiques physiques, stimulation mentale et détente émotionnelle.<br/><br/> Grâce à des approches naturelles adaptées, vous pourrez améliorer votre souplesse, renforcer votre tonus musculaire, soulager les tensions et apaiser l’esprit. Des moments créatifs et ludiques vous permettront d’exprimer vos émotions et de cultiver une attitude positive au quotidien. Offrez-vous une expérience globale pour mieux vivre et préserver votre santé.</p>
                <div className="pictos">
                    <div className="picto">
                        <img src="./image/picto06.jpg" alt="Zumba senior" />
                        <p>Zumba senior</p>
                    </div>
                    <div className="picto">
                        <img src="./image/picto07.jpg" alt="Peinture" />
                        <p>Peinture</p>
                    </div>
                    <div className="picto">
                        <img src="./image/picto05.jpg" alt="Ballon Gym" />
                        <p>Ballon Gym</p>
                    </div>
                    <div className="picto">
                        <img src="./image/picto02.jpg" alt="Lecture" />
                        <p>Lecture</p>
                    </div>
                    <div className="picto">
                        <img src="./image/picto03.jpg" alt="Musique" />
                        <p>Musique</p>
                    </div>
                    <div className="picto">
                        <img src="./image/picto04.jpg" alt="Art culinaire" />
                        <p>Art culinaire</p>
                    </div>
                    <div className="picto">
                        <img src="./image/picto01.jpg" alt="exercices musculaires" />
                        <p>Exercices musculaires</p>
                    </div>
                    <div className="picto">
                        <img src="./image/picto08.jpg" alt="autres" />
                        <p>Et encore d'autres ...</p>
                    </div>
                </div>
            </section>

            <section className="roll-image">
                <div className="vide"></div>
                <div className="qui">
                    <div className="qui-text">
                        <h2>Un programme complet, mais pour qui ?</h2>
                        <div className="separator01"></div>
                        <ul>
                            <li className="usercheck">Toute personne à partir de 40 ans souhaitant garder une bonne forme physique et mentale </li>
                            <li className="usercheck">Toute personne désireuse de se faire soigner par la nutrithérapie</li>
                            <li className="usercheck">Les personnes qui suivent ou veulent suivre un traitement médical</li>
                            <li className="usercheck">Les personnes âgées souffrant des douleurs articulaires ou de dos chroniques</li>
                            <li className="usercheck">Les malades atteints d’Alzheimer ou de la maladie de Parkinson au stade de début ou non</li>
                            <li className="usercheck">Les personnes en convalescence, ou qui viennent sortir d'hôpital.</li>
                            <li className="usercheck">Les personnes âgées en quête de paix, de soins et d'une ambiance conviviale.</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="modal" style={{display: sbe && 'block', top: mytop}} onClick={(e) => removemodal01(e)} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Mini-formation de 2 heures en santé & bien-être</h2>
                    </div>
                    <div className="modal-body">
                        <form ref={sberef} onSubmit={(e) => sendsbe(e)}>
                            <input type="hidden"value={'Santé - Bien-être'} />
                            <label htmlFor="">Comment s'inscrire ?</label><br/>
                            <select name="" id="">
                                <option value="Je me joins à un groupe existant">Je me joins à un groupe existant</option>
                                <option value="Je crée un groupe avec des amis">Je crée un groupe avec des amis</option>
                            </select><br/>
                            <label htmlFor="">Quand participer ?</label><br/>
                            <select name="" id="">
                                <option value="Je souhaite connaître les prochaines dates de formation">Je souhaite connaître les prochaines dates de formation</option>
                                <option value="Je veux choisir une date pour mon groupe">Je veux choisir une date pour mon groupe</option>
                            </select><br />
                            <input type="text"placeholder="Votre nom ..." /><br />
                            <input type="text"placeholder="Votre adresse e-mail ..." /><br />
                            <input type="text"placeholder="Votre N° de téléphone ... " /><br />
                            <input type="submit" value="Envoyer" />
                        </form>
                    </div>
                    <div className="modal-footer"></div>
                </div>
            </div>

            
            <div className="modal" style={{display: ntrp && 'block', top: mytop}} onClick={(e) => removemodal02(e)} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Mini-formation de 2 heures en nutrithérapie</h2>
                    </div>
                    <div className="modal-body">
                        <form ref={nttrpref} onSubmit={(e) => sendnttrp(e)} >
                            <input type="hidden"value={'Nutrithérapie'} />
                            <label htmlFor="">Comment s'inscrire ?</label><br/>
                            <select name="" id="">
                                <option value="Je me joins à un groupe existant">Je me joins à un groupe existant</option>
                                <option value="Je crée un groupe avec des amis">Je crée un groupe avec des amis</option>
                            </select><br/>
                            <label htmlFor="">Quand participer ?</label><br/>
                            <select name="" id="">
                                <option value="Je souhaite connaître les prochaines dates de formation">Je souhaite connaître les prochaines dates de formation</option>
                                <option value="Je veux choisir une date pour mon groupe">Je veux choisir une date pour mon groupe</option>
                            </select><br />
                            <input type="text"placeholder="Votre nom ..." /><br />
                            <input type="text"placeholder="Votre adresse e-mail ..." /><br />
                            <input type="text"placeholder="Votre N° de téléphone ... " /><br />
                            <input type="submit" value="Envoyer" />
                        </form>
                    </div>
                    <div className="modal-footer"></div>
                </div>
            </div>
        </div>
    )
}

export default Banneerhome03;