import React from "react";

const Bannierre = () => {
    return (
        <>
            <h2 style={{textAlign: 'center', maxWidth: '80%', margin: 'auto'}}>Un environnement propice au bien-être des seniors</h2>
            <div class="separator01"></div>
            <div className="grid-me">
                <div className="vide"></div>
                <div className="sec01">
                    <img src="./image/pro.jpg" style={{ zIndex: -1 }} className="standard-image" alt="personnes agées dans piscine" />
                </div>
                    <div className="sec02">
                        <div>   
                        <div className="pousseoir">
                            <h3>Un programme riche et varié</h3>
                            <p>
                            Notre programme "Beauté au naturel" comprend des soins du visage, de la manucure et de la pédicure pour permettre à nos résidents de se sentir beaux et bien dans leur peau.

                            <br/>
                            Pour une relaxation complète, notre programme "Détente" propose un jacuzzi et des massages pour soulager les tensions musculaires et améliorer la circulation sanguine.

                            <br/>

                            L'aromathérapie est une autre option pour nos résidents. Nous utilisons des huiles essentielles pour traiter différents troubles et aider à réduire le stress.
                            Nous proposons une "Thérapie non médicamenteuse", qui comprend divers jeux et ateliers pour stimuler la cognition de nos résidents, mais aussi des interactions sociales et la reminescence, pour aider nos résidents à se sentir connectés, heureux et de maintenir leur esprit actif et alerte.
                            Nous proposons divers programmes basés sur la médecine alternative comme la réflexothérapie, massage, Taichi, Reiki, magnetotherapie, et selon les traitements, des anti-douleurs sans produits chimiques afin de préserver la fonction rénale des personnes âgées; par exemple : appareil "Tens ", "Pach magnétique" , ...
                            <br /><br />
                            Des sorties sont régulièrement organisées avec nos résidents, dont une sortie en bord de mer une fois par an. 

                            </p>
                        </div>
                    </div>
                </div>
                    
                <div className="sec03">
                    <div>
                        <div className="pousseoir02">
                            <h3>La Nutri-thérapie</h3>
                            <p>
                                Notre programme de "Nutrithérapie" vise à utiliser l'alimentation comme médicament. Nous nous assurons que nos résidents reçoivent une alimentation saine et équilibrée pour maintenir leur santé. 
                                <br/><br/>
                                Sur la direction du Dr Josiane Rakotomalala, également coach en nutrithérapie, notre équipe élabore, au quotidien, des menus adaptés à chaque résident en fonction de leurs besoins et de leurs préférences. Nous prenons en compte les allergies alimentaires, les intolérances et les régimes spécifiques, et nous privilégions les aliments frais et locaux pour garantir une alimentation de qualité.
                                <br/><br/>

                                Nous offrons également des ateliers de cuisine et des séances d'information pour sensibiliser nos résidents à l'importance de l'alimentation dans la prévention et la gestion des maladies. 
                            </p>
                        </div>
                    </div>        
                </div>
                <div className="sec04">
                    <img src="./image/nutri.jpg" style={{ zIndex: -1 }} className="standard-image" alt="personnes agées avec ballon" />
                </div>
            </div>
        </>
    )
}

export default Bannierre;