import React, { useRef } from "react";
import Navigation from "../Template-Parts/Navigation";
import Footer from "../Template-Parts/Footer";
import Cibles from "../Components/Cibles";
import { useDispatch } from "react-redux";
import { sendacontact } from "../action/standardmail.action";


const Contact = () => {
    const contactref = useRef();
    const dispatch = useDispatch();
    const sendcontact = (e) => {
        e.preventDefault();
        const data = {
            'nom': contactref.current[0].value,
            'mail': contactref.current[1].value,
            'telephone': contactref.current[2].value,
            'objet': contactref.current[3].value,
            'message': contactref.current[4].value,
        }
        dispatch(sendacontact(data));
        contactref.current.reset();
    }

    return (
        <div classNameName="container">
            <Navigation />
            <section>
                <div className="nosban01">
                    <h1>Contact</h1>
                </div>
            </section>
            <section style={{margin: "5% auto"}}>
                <div className="bann-content">
                    <h2 style={{textAlign: 'center'}}>Prenons soin de nos parents</h2>
                    <div className="separator01"></div>
                    <p className="mytext01">
                        Pour toutes vos demandes sur nos services ou sur nos tarifs, veuillez nous envoyer directement un message via ce formulaire. Nous nous ferons un plaisir de répondre à vos questions et à vous fournir plus d'informations concernant votre séjour et celui de votre priche chez Shalom - Maison d'Accueil.
                    </p>
                </div>
            </section>

            <section>
                <div className="bann-content02">
                    <div className="form-image02">
                        <img src="./image/photogroup.jpg" className="border-me02 group-image" alt="les personnes âgées"/>
                    </div>
                    <div className="formcontainer">
                        <h2>Ecrivez-nous !</h2>
                        <div className="separator01"></div>
                        <form className="formcontainer form" ref={contactref} onSubmit={(e) => sendcontact(e)} >
                            <input type="text"  name="nom" placeholder="Votre nom"/>

                            <input type="email" name="email" id="" placeholder="Votre adresse e-mail"/>

                            <input type="text" name="telephone" id="" placeholder="Votre numéro de téléphone"/>

                            <input type="text" name="demande" id="" placeholder="Objet"/>

                            <textarea name="message" id="" cols="30" rows="10" placeholder="Votre message, ici ..."></textarea>

                            <input type="submit"  className="mybutton" value="Envoyer"/>

                        </form>
                    </div>
                </div>
            </section>
            
            <Cibles />
            <Footer />
        </div>
    )
}

export default Contact;