import React, { useEffect, useState } from 'react';

const Banneerhome05 = () => {

    const [alltestimonials, setAlltestimonials] = useState([]);
    const testimdb = [
        {
            id: 1,
            customername: 'Jane DOE',
            imgsrc: './image/testi02.PNG',
            alt: 'this is Jane Doe`\'s testimonials',
            msg : 'Nahafinaritra ny fianarana, ao tsara ny ambiance ! '
        },
        {
            id: 2,
            customername: 'John DOE',
            imgsrc: './image/testi04.PNG',
            alt: 'this is John Doe`\'s testimonials',
            msg : 'J\'ai presque retrouvé ma forme de mes 20 ans ! '
        },
        {
            id: 1,
            customername: 'Janie DOE',
            imgsrc: './image/testi03.PNG',
            alt: 'this is Janie Doe`\'s testimonials',
            msg : 'Tsara ny fikarakarana, mahafinaritra ny équipe rehetra! '
        },
    ];
    
    const showtestimonials = () => {
        const temptestimonials = testimdb && testimdb.map(testimonial => (
            <div key={testimonial.id} className="testim">
                <p><em>"{testimonial.msg}"</em></p>
                <img src={testimonial.imgsrc} alt={testimonial.alt} />
                <span className='mynameis'>{ testimonial.customername }</span>
            </div>
        ));
        setAlltestimonials(temptestimonials);
    }

    useEffect(() => {
        showtestimonials();
    }, []);
    
    return (
        <section className="stdcontainer" style={{background : 'white', padding: '20px 0'}}>
            <div className="Banneerhome05">
                <h2>Quelques témoingages ...</h2>
                <div className="separator01"></div>
                <div className="testimonialscontainer">
                    {alltestimonials}
                </div>
            </div>
        </section>
    )
}

export default Banneerhome05;
