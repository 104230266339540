import React from "react";
import Navigation from "../Template-Parts/Navigation";
import Footer from "../Template-Parts/Footer";
import Banneerhome06 from "../Components/Banneerhome06";
import Bannierre from "../Components/Bannierre";


const Internat = () => {
    return (
        <>
            <Navigation />
            <section>
                <div className="nosban01">
                    <h1>Internat</h1>
                </div>
            </section>
            <section>
                <div className="bann-content">
                    <h2>Un accueil personnalisé</h2>
                    <div className="separator01"></div>
                    <p className="mytext01" style={{width: '80%', margin: 'auto'}}>
                        Nous avons le plaisir de vous accueillir chez Shalom - Maison d'Accueil, pour un séjour en internat. Nous sommes conscients que les personnes âgées ont besoin de soins spécifiques pour leur bien-être. C'est pourquoi, nous avons créé un programme complet pour nos résidents, afin de répondre à leurs besoins individuels. Les seniors peuvent profiter pleinement de nos petits soins, et leurs proches rester sereins : nous prenons tout en main.
                    </p>
                </div>
            </section>
                    <div className="spacer01"></div>
            <section>
                <div className="bann-content">
                    <div>
                        <h2>Une formule unique</h2>
                        <div className="separator01"></div>
                        <div className="grid-caracteristiques">
                            <div className="caracteristiques">Caractéristiques</div>
                            <div className="details">Détails</div>

                            <div className="grid-conditions">Admission</div>
                            <div className="conditions-details">Un contrôle médical est effectué à l'entrée du patient, afin de compléter un dossier médical et administratif de suivi. Le résident est classNameé selon son dossier médical et les raisons de son séjour au sein de la maison d'accueil. L'état de santé du résident sera suivie de près par le médecin et toute l'équipe de Shalom - Maison durant son séjour au sein du centre afin d'en observer l'évolution ou son amélioration.</div>

                            <div className="grid-duree">Durée de séjour</div>
                            <div className="duree-details">Shalom - Maison d'accueil n'impose aucune durée de séjour fixe pour ses résidents. Il est possible de séjourner à l'internat à base d'une formule hebdomadaire, mensuelle ou annuelle. Veuillez nous contacter pour plus de détails correspandants.</div>

                            <div className="grid-restauration">Restauration</div>
                            <div className="restauration-details">Un service de restauration est fourni pour les résidents en internat. Un menu de groupe et individuel est programmé hebdomadairement par le Dr Josiane Rakotomalala, également coach en nutrithérapie, selon les besoins nutritionnels de chacun.</div>

                            <div className="grid-services">Nos services</div>
                            <div className="services-details">Un ensemble de services minimaux est inclus dans notre package "Internat" :  mise à disposition d'une chambre avec accès aux salles de bain et toilettes, service de blanchisserie, service d'entretien et de nettoyage des chambres, entretien et nettoyage de l'ensemble du bâtiment afin de garder un environnement agréable et sain pour les résidents, accès à un poste téléviseur, mise à disposition d'un téléphone et internet.</div>

                            <div className="grid-activites">Activités</div>
                            <div className="activites-details">Diverses activités sont organisées au quotidien et régulièrement chez Shalom - Maison d'accueil pour ses hôtes. Les activités sont programmées afin de casser la routine, à des fins de thérapie non médicamenteuse et pour assurer le bien-être de nos hôtes. La participation à ces activités dépend de l'état de santé des patients. Elles ne sont pas obligatoires, mais sont toutefois recommandées dans une optique de garder une santé optimale des patients. Des sorties sont régulièrement organisées, dont des vacances au bord de la mer, une fois par an.</div>


                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="bann-content">
                    <h2>La Maison d'Accueil - Shalom</h2>
                    <div className="separator01"></div>
                    <div className="cadre-image border-me02">
                        <div className="cadre-image01">
                            <img src="image/monlit.jpeg" className="shalom-gridimage" alt="mon lit chez Maison d'Accueil"/>
                        </div>
                        <div className="cadre-texte01">
                            <div className="inside-text">
                                <h3>Chambres confortables</h3>
                                <p>Nous mettons à disposition de nos hôtes des lits bien douillets. Une table de nuit et une armoire est à leur disposition pour leurs effets personnels.</p>
                                <div className="separator01"></div>
                            </div>
                        </div>
                        
                        <div className="cadre-texte02">
                            <div className="inside-text">
                                <h3>Séances de détente</h3>
                                <p>Un jacuzzi et une salle de massage est à disposition pour les séances de détente de nos hôtes.</p>
                                <div className="separator01"></div>
                            </div>
                        </div>
                        <div className="cadre-image02">
                            <img src="image/jacuzzi.jpeg" className="shalom-gridimage" alt="un jacuzzi pour les personnes âgées"/>
                        </div>

                        <div className="cadre-image03">
                            <img src="image/chaiserepos.jpeg" className="shalom-gridimage" alt="chaise pour les personnes âgées"/>
                        </div>
                        <div className="cadre-texte03">
                            <div className="inside-text">
                                <h3>Une espace commune</h3>
                                <p>Nous avons mis en place une espace commune qui favorise les interactions sociales entre nos hôtes.</p>
                                <div className="separator01"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Bannierre />

            <section>
                <div className="bann-content">
                    <div className="doctor-word border-me">
                        <img src="./image/ballons.jpeg" className="prez" alt="" style={{height: '140px'}} />  
                        <h2 style={{textAlign: 'left'}}>Les activités</h2>
                        <div className="separator02"></div>
                        <p>
                            Nous proposons des <b>activités physiques ou non</b>, adaptées aux personnes âgées, pour les aider à rester actifs et en bonne santé. La participation à ces activités relève de la volonté et l'état de santé du résident. Un planning hébdomadaire et mensuel de ces activités est établi en amont. Elles sont animées par des professionnels selon leur domaine respectif, avec l'aide de nos assistantes. Voici la liste de ces activités : <br/><br/> 
                        </p>
                        <ul>
                            <li><i className="fa fa-check usercheck"></i> <b>Activites physique </b>: Zumba senior, gymnastique douce spéciale senior (jeu de parachute, anneaux d’activité, ballon de manipulation,ballon gym, balles d’exercices main-doigt, billard Hollandais, mini-bowling, ...)</li>
                            <li><i className="fa fa-check usercheck"></i> <b>Diverses animations et jeux collectifs </b>: jeux de société, jeux de mémoire, lecture (nouvelles et revues de presse)</li>
                            <li><i className="fa fa-check usercheck"></i> <b>Atelier artistique </b>: musique, chant, peinture</li>
                            <li><i className="fa fa-check usercheck"></i> <b>Art culinaire</b></li>
                            <li><i className="fa fa-check usercheck"></i> <b>Divers évènements </b>: sorties, spectacle animé par les seniors, ...</li>
                        </ul>
                        
                    </div>
                </div>
            </section>
            <Banneerhome06 />
            <Footer />
        </>
    );
}

export default Internat;